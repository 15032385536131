<template>
    <div>
        <div
            class="modal fade"
            v-bind:class="[opt.isShowModal]"
            v-bind:style="{ display: opt.displayModal }"
            id="modalPatient"
            tabindex="-1"
            aria-labelledby="modalPatientLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
        >
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content p-4">
                    <div class="d-flex">
                        <h4 class="d-inline fw-bold title-text mb-4">
                            Tambah Pasien
                        </h4>
                        <button
                            type="button"
                            :disabled="inputRead"
                            v-on:click="closeModal()"
                            class="ms-auto btn-close"
                        ></button>
                    </div>

                    <h5 class="fw-bold title-text mb-3">
                        Identitas Orang Tua / Wali
                    </h5>
                    <form action="#" id="modal-form-patient">
                        <div
                            class="alert alert-danger"
                            role="alert"
                            v-bind:style="{ display: alert }"
                        >
                            {{ alertMessage }}
                        </div>
                        <div class="form-group mb-3">
                            <label for="birthStatus" class="mb-1"
                                >Nama Lengkap Orang Tua / Wali
                                <code>(*)</code></label
                            >
                            <input
                                type="text"
                                required
                                :readonly="inputRead"
                                v-model="namePatient"
                                name="namePatient"
                                class="form-control"
                                placeholder="Masukkan Nama Lengkap"
                                id="namePatient"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <label for="phonePatient" class="mb-1"
                                >No HP (WhatsApp) <code>(*)</code></label
                            >
                            <div class="row">
                                <div class="col-2 pe-1">
                                    <select
                                        name=""
                                        id=""
                                        class="form-select"
                                        required
                                        v-model="format_phone"
                                    >
                                        <option value="62">+62</option>
                                    </select>
                                </div>
                                <div class="col ps-1">
                                    <input
                                        type="number"
                                        required
                                        :readonly="inputRead"
                                        v-model="phonePatient"
                                        name="namePatient"
                                        class="form-control"
                                        placeholder="Contoh: 82123456789"
                                        id="phonePatient"
                                    />
                                </div>
                            </div>
                        </div>
                        <h5 class="fw-bold title-text mb-3">Identitas Anak</h5>
                        <div class="form-group mb-3">
                            <label for="childName" class="mb-1"
                                >Nama Lengkap Anak <code>(*)</code></label
                            >
                            <input
                                type="text"
                                required
                                :readonly="inputRead"
                                name="childName"
                                v-model="childName"
                                class="form-control"
                                placeholder="Masukkan Nama Anak"
                                id="childName"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <label for="birthdatePatient" class="mb-1"
                                >Tanggal Lahir <code>(*)</code></label
                            >
                            <input
                                type="date"
                                required
                                :readonly="inputRead"
                                name="birthdate"
                                v-model="birthdatePatient"
                                class="form-control"
                                placeholder="DD/MM/YYYY"
                                id="birthdatePatient"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <select
                                name="genderPatient"
                                id="genderPatient"
                                :readonly="inputRead"
                                v-model="genderPatient"
                                class="form-select mt-3"
                            >
                                <option value="">Pilih Jenis Kelamin</option>
                                <option value="L">Laki-Laki</option>
                                <option value="P">Perempuan</option>
                            </select>
                        </div>
                        <div class="form-group mb-3">
                            <label for="birthStatus" class="mb-1"
                                >Status Kelahiran</label
                            >
                            <input
                                type="text"
                                required
                                name="birthStatus"
                                :readonly="inputRead"
                                v-model="birthStatus"
                                class="form-control"
                                placeholder="Normal / Prematur / dll"
                                id="birthStatus"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <label for="notes" class="mb-1"
                                >Catatan Tambahan</label
                            >
                            <input
                                type="text"
                                required
                                :readonly="inputRead"
                                name="notes"
                                v-model="notes"
                                class="form-control"
                                placeholder="Alergic / Restriction"
                                id="notes"
                            />
                        </div>
                    </form>
                    <div>
                        <div class="d-grid">
                            <button
                                type="button"
                                :disabled="inputRead"
                                class="btn btn-main py-3 mb-3"
                                v-on:click="onSubmit()"
                            >
                                <i class="fa" v-bind:class="[spin]"></i> SIMPAN
                                DATA
                            </button>
                            <button
                                type="button"
                                :disabled="inputRead"
                                class="m-0 btn btn-edit"
                                v-on:click="closeModal()"
                            >
                                Tutup
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fade" v-bind:class="[opt.backdrop]"></div>
    </div>
</template>
<script>
import DokterService from '@/services/DokterService';
import { getError } from '@/utils/helpers';

export default {
    name: 'Addpasien',
    data() {
        return {
            opt: this.options,
            loadPasien: false,
            spin: '',
            inputRead: false,
            alert: 'none',
            alertMessage: '',
            listPasien: [],
            namePatient: '',
            phonePatient: '',
            childName: '',
            birthdatePatient: '',
            genderPatient: '',
            birthStatus: '',
            notes: '',
            format_phone: '62',
            dataset: this.set_data,
        };
    },
    props: {
        options: Object,
        set_data: Object,
    },
    created() {
        this.$emit('reload_emit', false);
    },
    methods: {
        closeModal() {
            this.opt.isShowModal = '';
            this.opt.displayModal = 'none';
            this.opt.backdrop = '';
        },
        async onSubmit() {
            this.spin = 'fa-spinner fa-spin';
            this.inputRead = true;
            var phone = ""
            if(this.phonePatient) {
                phone = this.format_phone + '' + this.phonePatient
            }
            const payload = {
                parent_name: this.namePatient,
                phone: phone,
                name: this.childName,
                birth_date: this.birthdatePatient,
                gender: this.genderPatient,
                birth_status: this.birthStatus,
                note: this.notes,
            };

            await DokterService.addPasien(payload)
                .then((response) => this.onSubmitResponse(response))
                .catch((error) => getError(error));
        },
        onSubmitResponse(response) {
            if (response.data.result == 'error') {
                this.spin = '';
                this.inputRead = false;
                this.alert = 'block';
                this.alertMessage = response.data.message;
            } else {
                this.opt.isShowModal = '';
                this.opt.displayModal = 'none';
                this.opt.backdrop = '';
                this.loadPasien = false;
                this.spin = '';
                this.inputRead = false;
                this.alert = 'none';
                this.alertMessage = '';
                this.namePatient = '';
                this.phonePatient = '';
                this.childName = '';
                this.birthdatePatient = '';
                this.genderPatient = '';
                this.birthStatus = '';
                this.notes = '';
                if (this.opt.returnResult == true) {
                    this.dataset.id = response.data.data.id;
                    this.dataset.birth = response.data.data.birth_date;
                    this.dataset.gender = response.data.data.gender_format;
                    this.dataset.gender_code = response.data.data.gender;
                    this.dataset.name = response.data.data.name;
                    this.dataset.parent = response.data.data.parent_name;
                    this.dataset.phone = response.data.data.phone;
                    this.dataset.age = response.data.data.age;
                    this.dataset.berat = 0;
                    this.dataset.tinggi = 0;
                } else {
                    this.$emit('reload_emit', true);
                }
            }
        },
    },
};
</script>
