<template>
<div>
     <div class="modal fade" 
      v-bind:class="[opt.isShowModal]"
            v-bind:style="{ display: opt.displayModal }"
            id="photoUpload" tabindex="-1" aria-labelledby="photoUploadLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content pb-3 rounded-default">
                        <div class="d-flex">
                            <button
                                style="margin:15px 22px;"
                                type="button"
                                v-on:click="closeModal()"
                                class="ms-auto btn-close"
                            ></button>
                        </div>
                        <!-- <div class="modal-top p-2">
                            <button type="button" v-on:click="closeModal()" class="ms-auto btn-close rounded-circle border border-3 mb-3 border-dark" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> -->
                        <div class="modal-body px-4">
                            <h1 class="title-text fw-bold">
                                Perbarui Foto Profil
                            </h1>
                               
                            <div class="photo-placeholder">
                                <input type="file" style="display:none;" accept="image/*" name="file" ref="file" v-on:change="onListenFile()"/>
                                <img v-if="file_preview != null" :src="file_preview" class="img-fluid"/>
                                <svg v-else width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Photo &#38; video/maximise">
                                        <path id="Path 28" d="M40.6667 106.75C37.8592 106.75 15.25 106.75 15.25 106.75V81.3333" stroke="#707EAE" stroke-width="2" stroke-linecap="round" />
                                        <path id="Path 28.1" d="M106.75 81.3333C106.75 84.1408 106.75 106.75 106.75 106.75L81.3335 106.75" stroke="#707EAE" stroke-width="2" stroke-linecap="round" />
                                        <path id="Path 28.2" d="M81.3333 15.2501C84.1408 15.2501 106.75 15.2501 106.75 15.2501L106.75 40.6667" stroke="#707EAE" stroke-width="2" stroke-linecap="round" />
                                        <path id="Path 28.3" d="M15.2498 40.6667C15.2498 37.8592 15.2498 15.25 15.2498 15.25L40.6665 15.25" stroke="#707EAE" stroke-width="2" stroke-linecap="round" />
                                    </g>
                                </svg>
                            </div>
                             <div
                                class="alert"
                                v-bind:class="[notif_alert.alertType]"
                                role="alert"
                                v-bind:style="{
                                    display: notif_alert.alert,
                                }"
                                >
                                {{ notif_alert.alertMessage }}
                            </div>
                            <div class="btn-footer pt-3 d-flex">
                                <div class="d-grid col-6 pe-2">
                                    <button v-on:click="openWindowFile()" :disabled="load" type="button" class="btn btn-add-main d-flex align-items-center justify-content-center" data-bs-dismiss="modal">
                                       <svg class="me-2" width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.93517 11.7796L14.1617 4.55304C15.0392 3.67631 16.4657 3.67631 17.3432 4.55304C18.2206 5.43052 18.2206 6.85774 17.3432 7.73522L7.40091 17.5477C5.9362 19.0124 3.56325 19.0124 2.09854 17.5477C0.633821 16.0837 0.633821 13.7093 2.09854 12.2453L11.9335 2.53784C13.984 0.487387 17.3094 0.487387 19.3569 2.53784C21.4088 4.58904 21.4088 7.91146 19.3584 9.96192L12.239 17.082" stroke="white" stroke-linecap="round" />
                                        </svg>
                                        Unggah Foto</button>
                                </div>
                                <div class="d-grid col-6 ps-2">
                                    <button v-if="load" type="button" class="btn btn-disabled" disabled><i class="fa fa-spinner fa-spin"></i>  Perubahan</button>
                                    <button v-else-if="files == null" type="button" class="btn btn-disabled" disabled>Simpan Perubahan</button>
                                    <button v-else type="button" class="btn btn-disabled" style="border:1px solid #4d3180; color:#4d3180;" v-on:click="onSubmit()">Simpan Perubahan</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
         <div class="fade" v-bind:class="[opt.backdrop]"></div>
</div>
</template>
<script>
import { getError } from '@/utils/helpers';
import AuthService from '@/services/AuthService';
import PicService from '@/services/PicService';

export default {
    name:"Upload",
    data() {
        return {
             opt: this.options,
             files:null,
             file_preview:null,
             load:false,
             notifset:this.notif,
             notif_alert: {
                alert: 'none',
                alertMessage: '',
                alertType: '',
            },
        }
    },
    props:{
        options:Object,
        notif:Object
    },
    methods: {
         closeModal() {
            this.opt.isShowModal = '';
            this.opt.displayModal = 'none';
            this.opt.backdrop = '';
            this.files = null;
            this.file_preview = null;
        },
        openWindowFile() {
            this.$refs.file.click()
        },
        onListenFile() {
            const [files] =  this.$refs.file.files
            this.files = files
            if(this.files) {
                 this.file_preview = URL.createObjectURL(this.files)
            }
        },
        async onSubmit() {
            this.load = true;
            var formData = new FormData()
            formData.append("avatar",this.files)
            if(this.opt.form == "dokter"){
                await AuthService.updateUserImage(formData)
                    .then((response) => this.onResponse(response))
                    .catch((error) => this.onError(error));
            } else {
                await PicService.updateUserImage(formData)
                    .then((response) => this.onResponse(response))
                    .catch((error) => this.onError(error));
            }
        },
        onError(error) {
              this.notif_alert.alertType ="alert-danger"
              this.notif_alert.alert="block"
              this.notif_alert.alertMessage = getError(error)
              this.load = false
        },
        async onResponse(response) {
            if(response.data.result == "error") {
                this.notif_alert.alertType ="alert-danger"
                this.notif_alert.alert="block"
                this.notif_alert.alertMessage= response.data.message
            } else {
                if(this.opt.form == "dokter"){
                    await this.$store.dispatch('auth/getAuthUser');
                } else {
                    await this.$store.dispatch('pic/getAuthUser');
                }
                this.notifset.alertType = "alert-success"
                this.notifset.alert="block"
                this.notifset.alertMessage= response.data.message
                this.closeModal()
                this.files = null
                this.file_preview = null
            }
            this.load = false
        },
    }
}
</script>
