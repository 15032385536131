<template>
    <div class="container-fluid">
        <div
            class="page-content ml-sm-0"
            id="page-content"
        >
            <div class="header-greeting mb-3 pe-md-5">
                <p class="p-0 m-0">Recopedia</p>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <h1 class="fw-bolder">Artikel</h1>
                    </div>
                </div>
            </div>
            <section id="article-page">
                <div class="wrapper">
                    <div class="row">
                        <div class="col-lg-12" v-if="loadAll">
                            <h1 class="text-center">
                                <i class="fa fa-spinner fa-spin"></i>
                            </h1>
                        </div>
                        <template v-for="item in listArtikel" v-else>
                            <div class="col-12 col-sm-6 col-md-4 mb-2" :key="item.id">
                                <div
                                    class="card h-100"
                                    v-on:click="onDetail(item)"
                                    style="cursor:pointer;"
                                >
                                    <div class="thumbnail">
                                        <img
                                            v-bind:src="item.image"
                                            alt="thumb-1"
                                            class="img-fluid"
                                        />
                                    </div>
                                    <div class="desc p-3">
                                        <div class="duration px-2 py-1">
                                            <p class="p-0 m-0">
                                                {{ item.created_at_format }}
                                            </p>
                                        </div>
                                        <div class="title mt-2">
                                            {{ item.title.substring(0, 77) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div
                    class="d-flex justify-content-center mt-3"
                    v-if="isLoadMoreShow"
                >
                    <button
                        v-if="loadPaging"
                        class="btn btn-add-main px-5"
                        disabled
                    >
                        <i class="fa fa-spinner fa-spin"></i> Load More
                    </button>
                    <button
                        v-else
                        class="btn btn-add-main px-5 mb-3"
                        v-on:click="onLoadMore()"
                    >
                        Load More
                    </button>
                </div>
            </section>
        </div>
        <div
            class="modal fade"
            v-bind:class="[isShowModal]"
            v-bind:style="{ display: displayModal }"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="wrapper">
                        <div class="card">
                            <div class="text-end p-3">
                                <button
                                    type="button"
                                    class="btn-close"
                                    v-on:click="closeModal()"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div class="modal-body p-0">
                                <img
                                    v-bind:src="detail.image"
                                    alt="thumb-1"
                                    class="img-fluid"
                                />
                            </div>
                            <div class="desc p-3">
                                <div class="duration px-2 py-1 mb-3">
                                    <p class="p-0 m-0">{{ detail.created }}</p>
                                </div>
                                <div class="title mt-2">
                                    <p>{{ detail.title }}</p>
                                </div>

                                <p v-html="detail.deskripsi"></p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Understood</button>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="fade" v-bind:class="[backdrop]"></div>
    </div>
</template>
<script>
import DokterService from '@/services/DokterService';
import { getError } from '@/utils/helpers';

export default {
    name: 'Artikel',
    data() {
        return {
            isShowModal: '',
            backdrop: '',
            displayModal: 'none',
            listArtikel: [],
            listPush: [],
            page: 1,
            loadAll: false,
            loadPaging: false,
            isLoadMoreShow: false,
            detail: {
                image: '',
                deskripsi: '',
                title: '',
                created: '',
            },
        };
    },
    created() {
        this.loadAll = true;
        this.onLoadPost();
        document.title = 'Artikel'
        this.$store.dispatch('auth/setMenuActive', { value: 'artikel' });
    },
    computed: {
        computeMarginLeft: function () {
            const width = window.innerWidth;
            if (width >= 768) {
                return '280px';
            }

            if (width < 768) {
                return '80px';
            }

            return '0px';
        },
    },
    methods: {
        async onLoadPost() {
            const payload = {
                params: {
                    category: 'artikel',
                    perpage:12,
                    page: this.page,
                },
            };
            await DokterService.getPost(payload)
                .then((response) => this.onResponse(response))
                .catch((error) => getError(error));
        },
        async onResponse(response) {
            await response.data.data.forEach((value) => this.onAdd(value));
            if (response.data.data.length > 0) {
                this.page++;
            }

            if (response.data.current_page < response.data.last_page) {
                this.isLoadMoreShow = true;
            } else {
                this.isLoadMoreShow = false;
            }

            this.loadAll = false;
            this.loadPaging = false;
        },
        onAdd(result) {
            this.listArtikel.push(result);
        },
        onLoadMore() {
            this.loadPaging = true;
            this.onLoadPost();
        },
        onDetail(item) {
            if (this.isShowModal == 'show') {
                this.isShowModal = '';
                this.displayModal = 'none';
                this.backdrop = '';
                this.detail.title = '';
                this.detail.image = '';
                this.detail.deskripsi = '';
                this.detail.created = '';
            } else {
                this.isShowModal = 'show';
                this.displayModal = 'block';
                this.backdrop = 'modal-backdrop show';
                this.detail.title = item.title;
                this.detail.image = item.image;
                this.detail.deskripsi = item.body;
                this.detail.created = item.created_at_format;
            }
        },
        closeModal() {
            this.backdrop = '';
            this.isShowModal = '';
            this.displayModal = 'none';
            this.detail.title = '';
            this.detail.image = '';
            this.detail.deskripsi = '';
            this.detail.created = '';
        },
    },
};
</script>
