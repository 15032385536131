<template>
    <div>
        <div
            class="modal fade"
            v-bind:class="[opt.isShowModal]"
            v-bind:style="{ display: opt.displayModal }"
            id="resultModal"
            tabindex="-1"
            aria-labelledby="resultModalLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
        >
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content p-3">
                     <div class="d-flex">
                        <button
                            type="button"
                            v-on:click="closeModal()"
                            class="ms-auto btn-close"
                        ></button>
                    </div>
                    <!-- <div class="modal-top px-4">
                        <button
                            type="button"
                            v-on:click="closeModal()"
                            class="
                                ms-auto
                                btn-close
                                rounded-circle
                                border border-3
                                mb-3
                                border-dark
                            "
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style="top: -55px"
                        ></button>
                    </div> -->
                    <ul
                        class="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                    >
                        <li class="nav-item me-2" role="presentation">
                            <button
                                class="nav-link avenir-light py-3  px-5"
                                id="pills-home-tab"
                                v-bind:class="[tabs == 'value' ? 'active' : '']"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-result"
                                type="button"
                                role="tab"
                                aria-controls="pills-result"
                                aria-selected="true"
                                v-on:click="onTabs('value')"
                            >
                                Hasil Pemeriksaan
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link avenir-light py-3"
                                id="pills-chart-tab"
                                v-bind:class="[tabs == 'chart' ? 'active' : '']"
                                v-on:click="onTabs('chart')"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-chart"
                                type="button"
                                role="tab"
                                aria-controls="pills-chart"
                                aria-selected="false"
                            >
                                Chart
                            </button>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div
                            class="tab-pane fade "
                            v-bind:class="[tabs == 'value' ? 'show active' : '']"
                            id="pills-result"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                        >
                            <!-- ? Hasil Periksa -->
                            <div class="data-result">
                                <template v-if="loadKalkulator">
                                    <h3 class="text-center">
                                        <i
                                            style="color: #4d3180"
                                            class="fa fa-spinner fa-spin"
                                        ></i>
                                    </h3>
                                </template>
                                <div class="container-fluid" v-else>
                                    <div class="row">
                                        <div class="col-12 col-md-4">
                                            <div class="label-1 avenir-light">
                                                Orang Tua / Wali
                                            </div>
                                            <h3 class="title-text fw-bold">
                                                {{ dataset.parent }}
                                            </h3>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="label-1 avenir-light">
                                                Anak
                                            </div>
                                            <h3 class="title-text fw-bold">
                                                {{ dataset.name }}
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12 col-md-4">
                                            <div class="detail-wrapper d-flex">
                                                <div>
                                                    <div class="detail-title">
                                                        No HP (WhatsApp)
                                                    </div>
                                                    <div
                                                        class="detail-main-text"
                                                    >
                                                        {{ dataset.phone }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="row">
                                                <div class="col-12 col-md-8">
                                                    <div
                                                        class="
                                                            detail-wrapper
                                                            d-flex
                                                        "
                                                    >
                                                        <div>
                                                            <div
                                                                class="
                                                                    detail-title
                                                                "
                                                            >
                                                                Tanggal Periksa
                                                            </div>
                                                            <div
                                                                class="
                                                                    detail-main-text
                                                                "
                                                                v-if="
                                                                    output !=
                                                                    null
                                                                "
                                                            >
                                                                {{
                                                                    output.tanggal_periksa
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <div
                                                        class="
                                                            detail-wrapper
                                                            d-flex
                                                        "
                                                    >
                                                        <div>
                                                            <div
                                                                class="
                                                                    detail-title
                                                                "
                                                            >
                                                                Umur
                                                            </div>
                                                            <div
                                                                class="
                                                                    detail-main-text
                                                                "
                                                                v-if="
                                                                    output !=
                                                                    null
                                                                "
                                                            >
                                                                {{
                                                                    output.umur
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-12 col-md-3 mt-3"
                                                >
                                                    <div
                                                        class="
                                                            detail-wrapper
                                                            d-flex
                                                        "
                                                    >
                                                        <div>
                                                            <div
                                                                class="
                                                                    detail-title
                                                                "
                                                            >
                                                                Berat Badan
                                                            </div>
                                                            <div
                                                                class="
                                                                    detail-main-text
                                                                "
                                                                v-if="
                                                                    output !=
                                                                    null
                                                                "
                                                            >
                                                                {{
                                                                    output.berat_badan_formatted
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-12 col-md-3 mt-3"
                                                >
                                                    <div
                                                        class="
                                                            detail-wrapper
                                                            d-flex
                                                        "
                                                    >
                                                        <div>
                                                            <div
                                                                class="
                                                                    detail-title
                                                                "
                                                            >
                                                                Tinggi Badan
                                                            </div>
                                                            <div
                                                                class="
                                                                    detail-main-text
                                                                "
                                                                v-if="
                                                                    output !=
                                                                    null
                                                                "
                                                            >
                                                                {{
                                                                    output.tinggi_badan_formatted
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 my-3">
                                            <h3 class="title-text mt-3">
                                                1. Penilaian Status Nutrisi
                                            </h3>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="detail-wrapper d-flex">
                                                <div>
                                                    <div class="detail-title">
                                                        1a. Penilaian Status
                                                        Berat Badan (BB/U)
                                                    </div>
                                                    <div
                                                        class="detail-main-text"
                                                        v-if="output != null"
                                                    >
                                                        <p
                                                            v-html="
                                                                output
                                                                    .status_berat_badan
                                                                    .rawoutput
                                                            "
                                                        ></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="detail-wrapper d-flex">
                                                <div>
                                                    <div class="detail-title">
                                                        1b. Penilaian Status
                                                        Tinggi Badan (TB/U)
                                                    </div>
                                                    <div
                                                        class="detail-main-text"
                                                        v-if="output != null"
                                                    >
                                                        <p
                                                            v-html="
                                                                output
                                                                    .status_tinggi_badan
                                                                    .rawoutput
                                                            "
                                                        ></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="detail-wrapper d-flex">
                                                <div>
                                                    <div class="detail-title">
                                                        1c. Penilaian Status
                                                        Gizi (BB/TB)
                                                    </div>
                                                    <div
                                                        class="detail-main-text"
                                                        v-if="output != null"
                                                    >
                                                        <p
                                                            v-html="
                                                                output
                                                                    .status_gizi
                                                                    .rawoutput
                                                            "
                                                        ></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 my-3">
                                            <h3 class="title-text mt-3">
                                                2. Menghitung Kebutuhan Kalori
                                            </h3>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="detail-wrapper d-flex">
                                                <div>
                                                    <div class="detail-title">
                                                        2a. Menentukan BB Ideal
                                                    </div>
                                                    <div
                                                        class="detail-main-text"
                                                        v-if="output != null"
                                                    >
                                                        <p
                                                            v-html="
                                                                output
                                                                    .berat_badan_ideal
                                                                    .rawoutput
                                                            "
                                                        ></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="detail-wrapper d-flex">
                                                <div>
                                                    <div class="detail-title">
                                                        2b. Menentukan Height
                                                        Age
                                                    </div>
                                                    <div
                                                        class="detail-main-text"
                                                        v-if="output != null"
                                                    >
                                                        <p
                                                            v-html="
                                                                output
                                                                    .height_age
                                                                    .rawoutput
                                                            "
                                                        ></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="detail-wrapper d-flex">
                                                <div>
                                                    <div class="detail-title">
                                                        2c. Menentukan RDA
                                                        (Requirement Daily
                                                        Allowance)
                                                    </div>
                                                    <div
                                                        class="detail-main-text"
                                                        v-if="output != null"
                                                    >
                                                        <p
                                                            v-html="
                                                                output.rda
                                                                    .rawoutput
                                                            "
                                                        ></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="detail-wrapper d-flex">
                                                <div>
                                                    <div class="detail-title">
                                                        2d. Menentukan Kebutuhan
                                                        Kalori Harian
                                                    </div>
                                                    <div
                                                        class="detail-main-text"
                                                        v-if="output != null"
                                                    >
                                                        <p
                                                            v-html="
                                                                output
                                                                    .kalori_harian
                                                                    .rawoutput
                                                            "
                                                        ></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3" v-if="result.output.status_gizi.isPercentileMax">
                                            <div class="detail-wrapper d-flex">
                                                <div>
                                                    <div class="detail-title">
                                                        Keterangan
                                                    </div>
                                                    <div
                                                        class="detail-main-text"
                                                        v-if="output != null"
                                                    >
                                                        <p> Segera rujuk pasien untuk konseling gizi </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" v-if="!result.output.status_gizi.isPercentileMax">
                                        <div class="col-12 my-3">
                                            <h3 class="title-text mt-3">
                                                3. Saran Formula
                                            </h3>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="detail-wrapper d-flex">
                                                <div>
                                                    <div class="detail-title">
                                                        3a. Menghitung Kebutuhan
                                                        Kalori Harian dari susu
                                                    </div>
                                                    <div
                                                        class="detail-main-text"
                                                    >
                                                        % kalori dari susu
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <vue-slider
                                                    v-model="persen_kalori"
                                                    :data="dataSlider"
                                                    :lazy="true"
                                                    @change="onChangSlider"
                                                ></vue-slider>
                                                <br />
                                            </div>
                                            <div class="mt-3">
                                                <div
                                                    class="h5 title-text"
                                                    v-if="loadSlider"
                                                >
                                                    Kalori dari susu:
                                                    <i
                                                        class="
                                                            fa
                                                            fa-spinner
                                                            fa-spin
                                                        "
                                                    ></i>
                                                </div>
                                                <div
                                                    class="h5 title-text"
                                                    v-else-if="
                                                        result != null &&
                                                        loadSlider == false
                                                    "
                                                >
                                                    <p
                                                        v-html="
                                                            result.kaloriHarianONSOutput3a
                                                        "
                                                    ></p>
                                                </div>
                                                <div class="row">
                                                    <div
                                                        class="col-12 col-md-6"
                                                    >
                                                        <div
                                                            class="
                                                                dropdown-container
                                                            "
                                                        >
                                                            <select
                                                                name="dropwdown1"
                                                                v-model="
                                                                    category
                                                                "
                                                                class="
                                                                    form-select
                                                                    result-dropdown
                                                                "
                                                                v-on:change="
                                                                    onChangeCategory()
                                                                "
                                                            >
                                                                <option
                                                                    value="0"
                                                                    selected
                                                                >
                                                                    Pilih
                                                                    Kategori
                                                                </option>
                                                                <template
                                                                    v-for="item in listCate"
                                                                >
                                                                    <option
                                                                        :value="
                                                                            item.id
                                                                        "
                                                                        :key="
                                                                            item.id
                                                                        "
                                                                    >
                                                                        {{
                                                                            item.name
                                                                        }}
                                                                    </option>
                                                                </template>
                                                            </select>
                                                            <i
                                                                class="
                                                                    fas
                                                                    fa-chevron-down
                                                                "
                                                            ></i>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="col-12 col-md-6"
                                                    >
                                                        <div
                                                            class="
                                                                dropdown-container
                                                            "
                                                        >
                                                            <select
                                                                name="dropwdown1"
                                                                v-model="susu"
                                                                class="
                                                                    form-select
                                                                    result-dropdown
                                                                "
                                                                v-on:change="
                                                                    onChangeSusu()
                                                                "
                                                            >
                                                                <option
                                                                    value="0"
                                                                    selected
                                                                >
                                                                    Pilih Susu
                                                                </option>
                                                                <template
                                                                    v-for="item in listSusu"
                                                                >
                                                                    <option
                                                                        :value="
                                                                            item.id
                                                                        "
                                                                        :key="
                                                                            item.id
                                                                        "
                                                                    >
                                                                        {{
                                                                            item.name
                                                                        }}
                                                                    </option>
                                                                </template>
                                                            </select>
                                                            <i
                                                                class="
                                                                    fas
                                                                    fa-chevron-down
                                                                "
                                                            ></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <template v-if="susu != 0">
                                            <h3
                                                class="text-center"
                                                v-if="loadSusu"
                                            >
                                                <i
                                                    style="color: #4d3180"
                                                    class="
                                                        fa fa-spinner fa-spin
                                                    "
                                                ></i>
                                            </h3>
                                            <template v-else>
                                                <div class="col-12 mb-3">
                                                    <div
                                                        class="
                                                            detail-wrapper
                                                            d-flex
                                                        "
                                                    >
                                                        <div>
                                                            <div
                                                                class="
                                                                    detail-title
                                                                "
                                                            >
                                                                Menghitung
                                                                Kebutuhan gram
                                                                {{
                                                                    result.susu
                                                                        .name
                                                                }}
                                                            </div>
                                                            <div
                                                                class="
                                                                    detail-main-text
                                                                "
                                                            >
                                                                <p
                                                                    v-html="
                                                                        result.kebutuhan_gram_susu
                                                                    "
                                                                ></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-3">
                                                        <div
                                                            class="
                                                                row
                                                                align-items-center
                                                            "
                                                        >
                                                            <label
                                                                class="
                                                                    col-sm-2
                                                                    col-form-label
                                                                    title-text
                                                                "
                                                                >Frekuensi</label
                                                            >
                                                            <div
                                                                class="col-4 col-sm-3"
                                                            >
                                                                <input
                                                                    type="number"
                                                                    placeholder="Contoh : 3"
                                                                    v-model="
                                                                        dosis_saji
                                                                    "
                                                                    class="
                                                                        form-control
                                                                    "
                                                                />
                                                            </div>
                                                            <div
                                                                class="
                                                                    col-auto
                                                                    avenir-light
                                                                    title-text
                                                                "
                                                            >
                                                                X Sehari
                                                            </div>
                                                            <div
                                                                class="
                                                                    col-auto
                                                                    avenir-light
                                                                "
                                                            >
                                                                <button
                                                                    v-if="
                                                                        loadDosisSaji
                                                                    "
                                                                    class="
                                                                        btn
                                                                        btn-add-main
                                                                        rounded
                                                                    "
                                                                    disabled
                                                                >
                                                                    <i
                                                                        class="
                                                                            fa
                                                                            fa-spinner
                                                                            fa-spin
                                                                        "
                                                                    ></i>
                                                                    Hitung
                                                                </button>
                                                                <button
                                                                    v-else
                                                                    class="
                                                                        btn
                                                                        btn-add-main
                                                                        rounded
                                                                    "
                                                                    v-on:click="
                                                                        onCalculateDosisSaji()
                                                                    "
                                                                >
                                                                    Hitung
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-3">
                                                        <label
                                                            class="
                                                                avenir-light
                                                                title-text
                                                            "
                                                            ><span
                                                                class="
                                                                    title-text
                                                                    fw-bold
                                                                "
                                                                ><p
                                                                    v-html="
                                                                        result.dosisSajiOutput
                                                                    "
                                                                ></p></span
                                                        ></label>
                                                    </div>
                                                    <div class="mt-3">
                                                        <div
                                                            class="
                                                                row
                                                                align-items-center
                                                            "
                                                        >
                                                            <label
                                                                class="
                                                                    col-sm-2
                                                                    col-form-label
                                                                    title-text
                                                                "
                                                                >Volume yang di inginkan</label
                                                            >
                                                            <div
                                                                class="col-4 col-sm-3"
                                                            >
                                                                <input
                                                                    type="number"
                                                                    placeholder="Contoh : 50"
                                                                    v-model="
                                                                        volume
                                                                    "
                                                                    class="
                                                                        form-control
                                                                    "
                                                                />
                                                            </div>
                                                            <div
                                                                class="
                                                                    col-auto
                                                                    avenir-light
                                                                "
                                                            >
                                                                <button
                                                                    v-if="
                                                                        loadVolume
                                                                    "
                                                                    class="
                                                                        btn
                                                                        btn-add-main
                                                                        rounded
                                                                    "
                                                                    disabled
                                                                >
                                                                    <i
                                                                        class="
                                                                            fa
                                                                            fa-spinner
                                                                            fa-spin
                                                                        "
                                                                    ></i>
                                                                    Hitung
                                                                </button>
                                                                <button
                                                                    v-else
                                                                    class="
                                                                        btn
                                                                        btn-add-main
                                                                        rounded
                                                                    "
                                                                    v-on:click="
                                                                        onCalcaulateVolume()
                                                                    "
                                                                >
                                                                    Hitung
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-3">
                                                    <div
                                                        class="
                                                            detail-wrapper
                                                            d-flex
                                                        "
                                                    >
                                                        <div>
                                                            <div
                                                                class="
                                                                    detail-title
                                                                "
                                                            >
                                                                Menghitung
                                                                Kebutuhan Scoop
                                                                {{
                                                                    result.susu
                                                                        .name
                                                                }}
                                                            </div>
                                                            <div
                                                                class="
                                                                    detail-main-text
                                                                    title-text
                                                                "
                                                                v-if="
                                                                    result !=
                                                                    null
                                                                "
                                                            >
                                                                <span
                                                                    v-html="
                                                                        result.kebutuhan_scoop_susu
                                                                    "
                                                                ></span
                                                                ><br />
                                                                <span
                                                                    v-html="
                                                                        result.jumlah_scoop_per_saji
                                                                    "
                                                                ></span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="mt-3">
                                                        <div
                                                            class="
                                                                row
                                                                align-items-center
                                                            "
                                                        >
                                                            <label
                                                                class="
                                                                    col-sm-2
                                                                    col-form-label
                                                                    title-text
                                                                "
                                                                >Saran
                                                                pembulatan per
                                                                saji:</label
                                                            >
                                                            <div
                                                                class="col-4 col-sm-3"
                                                            >
                                                                <input
                                                                    type="number"
                                                                    placeholder="Contoh : 3"
                                                                    v-model="
                                                                        saran_saji
                                                                    "
                                                                    class="
                                                                        form-control
                                                                    "
                                                                />
                                                            </div>
                                                            <div
                                                                class="
                                                                    col-auto
                                                                    avenir-light
                                                                    title-text
                                                                "
                                                            >
                                                                Scoop
                                                            </div>
                                                            <div
                                                                class="
                                                                    col-auto
                                                                    avenir-light
                                                                "
                                                            >
                                                                <button
                                                                    v-if="
                                                                        loadSaranSaji
                                                                    "
                                                                    class="
                                                                        btn
                                                                        btn-add-main
                                                                        rounded
                                                                    "
                                                                    disabled
                                                                >
                                                                    <i
                                                                        class="
                                                                            fa
                                                                            fa-spinner
                                                                            fa-spin
                                                                        "
                                                                    ></i>
                                                                    Hitung
                                                                </button>
                                                                <button
                                                                    v-else
                                                                    class="
                                                                        btn
                                                                        btn-add-main
                                                                        rounded
                                                                    "
                                                                    v-on:click="
                                                                        onCalculateSaranSaji()
                                                                    "
                                                                >
                                                                    Hitung
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="mt-3">
                                                            <label
                                                                class="
                                                                    avenir-light
                                                                    title-text
                                                                "
                                                                ><p
                                                                    v-html="
                                                                        result.jumlah_scoop_per_hari
                                                                    "
                                                                ></p
                                                            ></label>
                                                        </div>
                                                    </div>
                                                    <label
                                                        class="
                                                            avenir-light
                                                            title-text
                                                        "
                                                        v-html="
                                                            result.jumlah_1_scoop
                                                        "
                                                    ></label
                                                    ><br />
                                                    <label
                                                        class="
                                                            avenir-light
                                                            title-text
                                                        "
                                                        v-html="
                                                            result.jumlah_gram_susu_per_hari
                                                        "
                                                    ></label>
                                                </div>
                                                <div class="col-12 mb-3">
                                                    <div
                                                        class="
                                                            detail-wrapper
                                                            d-flex
                                                        "
                                                    >
                                                        <div>
                                                            <div
                                                                class="
                                                                    detail-title
                                                                "
                                                            >
                                                                Menghitung
                                                                kebutuhan untuk
                                                                2 minggu
                                                            </div>
                                                            <div
                                                                class="
                                                                    detail-main-text
                                                                "
                                                                v-if="
                                                                    result !=
                                                                    null
                                                                "
                                                            >
                                                                <span
                                                                    v-html="
                                                                        result.kebutuhan_2_minggu
                                                                    "
                                                                ></span
                                                                ><br />
                                                                Jumlah kaleng
                                                                {{
                                                                    result.susu
                                                                        .name
                                                                }}
                                                                400gr:
                                                                {{
                                                                    result.jumlah_kaleng
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-3">
                                                    <div class="milk-result">
                                                        <h5
                                                            class="
                                                                title-text
                                                                fw-bold
                                                            "
                                                        >
                                                            Resep
                                                        </h5>
                                                        <img
                                                            style="width:200px;"
                                                            v-if="img_susu == ''"
                                                            src="../assets/images/pedia-milk.png"
                                                            alt="milk"
                                                            class="
                                                                img-fluid
                                                                my-3
                                                            "
                                                        />
                                                         <img
                                                             style="width:200px;"
                                                            v-else
                                                            :src="img_susu"
                                                            alt="milk"
                                                            class="
                                                                img-fluid
                                                                my-3
                                                            "
                                                        />
                                                        <h5
                                                            class="
                                                                text-center
                                                                title-text
                                                                fw-bold
                                                            "
                                                        >
                                                            {{ result.resep }}
                                                        </h5>
                                                    </div>
                                                </div>
                                                
                                            </template>
                                          
                                        </template>
                                    </div>
                                    <div class="row" v-if="!result.output.status_gizi.isPercentileMax">
                                          <div class="co-12 mb-3">
                                                <div class="form-group">
                                                    <label class="control-label text-black mb-3">Catatan</label>
                                                    <textarea rows="5" class="form-control" placeholder="Catatan" v-model="catatan"></textarea>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <div class="d-grid">
                                                 <button v-if="wa_load" class="btn btn-wa" disabled>
                                                    <i
                                                        class="
                                                            fa
                                                            fa-spinner
                                                            fa-spin
                                                            me-3
                                                        "
                                                    ></i>
                                                    Simpan & Kirim Hasil Pemeriksaan ke
                                                    WhatsApp Pasien
                                                </button>
                                                <button v-else v-on:click="sendWa()" class="btn btn-wa"  :disabled="susu == 0 && !result.output.status_gizi.isPercentileMax ? true : false">
                                                    <i
                                                        class="
                                                            fab
                                                            fa-whatsapp
                                                            me-3
                                                        "
                                                    ></i>
                                                    Simpan & Kirim Hasil Pemeriksaan ke
                                                    WhatsApp Pasien
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="d-grid">
                                                <button
                                                    v-if="loadSave"
                                                    class="btn btn-main-alt"
                                                    type="button"
                                                    disabled
                                                >
                                                    <i
                                                        class="
                                                            fa
                                                            fa-spinner
                                                            fa-spin
                                                        "
                                                    ></i>
                                                    Simpan Hasil Pemeriksaan
                                                </button>
                                                <button
                                                    v-else
                                                    class="btn btn-main-alt"
                                                    type="button"
                                                    :disabled="susu == 0 && !result.output.status_gizi.isPercentileMax ? true : false"
                                                    v-on:click="onSave()"
                                                >
                                                    Simpan Hasil Pemeriksaan
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="pills-chart"
                            v-bind:class="[tabs == 'chart' ? 'show active' : '']"
                            role="tabpanel"
                            aria-labelledby="pills-chart-tab"
                        >
                          <template v-if="loadKalkulator">
                                <h3 class="text-center">
                                    <i
                                        style="color: #4d3180"
                                        class="fa fa-spinner fa-spin"
                                    ></i>
                                </h3>
                          </template>
                          <div class="row">
                            <div class="col-md-6 col-xs-12" style="margin-top:10px">
                                <div v-if="dataset.gender == 'Perempuan'" class="card" style="background-color:#e45bbf;padding:20px;">
                                     <canvas id="chart_priview_wfa" style="background-color:white;"></canvas>
                                     <p class="text-muted text-center" style="font-size: 14px;padding-bottom:10px;background-color:#fff;"><small>Source: <a href="https://www.who.int/tools/child-growth-standards/standards/weight-for-age" target="_blank">WHO</a></small></p>
                                </div>
                                  <div v-else class="card" style="background-color:#3096d7;padding:20px;">
                                     <canvas id="chart_priview_wfa" style="background-color:white;"></canvas>
                                     <p class="text-muted text-center" style="font-size: 14px;padding-bottom:10px;background-color:#fff;"><small>Source: <a href="https://www.who.int/tools/child-growth-standards/standards/weight-for-age" target="_blank">WHO</a></small></p>
                                </div>
                            </div>
                            <div class="col-md-6 col-xs-12" style="margin-top:10px">
                                <div v-if="dataset.gender == 'Perempuan'" class="card" style="background-color:#e45bbf;padding:20px;">
                                    <canvas id="chart_priview_wfl" style="background-color:white;"></canvas>
                                    <p class="text-muted text-center" style="font-size: 14px;padding-bottom:10px;background-color:#fff;"><small>Source: <a href="https://www.who.int/tools/child-growth-standards/standards/weight-for-length-height" target="_blank">WHO</a></small></p>
                                </div>
                                <div v-else class="card" style="background-color:#3096d7;padding:20px;">
                                    <canvas id="chart_priview_wfl" style="background-color:white;"></canvas>
                                    <p class="text-muted text-center" style="font-size: 14px;padding-bottom:10px;background-color:#fff;"><small>Source: <a href="https://www.who.int/tools/child-growth-standards/standards/weight-for-length-height" target="_blank">WHO</a></small></p>
                                </div>
                            </div>
                            <div class="col-md-6 col-xs-12" style="margin-top:10px">
                                <div v-if="dataset.gender == 'Perempuan'" class="card" style="background-color:#e45bbf;padding:20px;">
                                    <canvas id="chart_priview_lfa" style="background-color:white;"></canvas>
                                    <p class="text-muted text-center" style="font-size: 14px;padding-bottom:10px;background-color:#fff;"><small>Source: <a href="https://www.who.int/tools/child-growth-standards/standards/length-height-for-age" target="_blank">WHO</a></small></p>
                                </div>
                                <div v-else class="card" style="background-color:#3096d7;padding:20px;">
                                    <canvas id="chart_priview_lfa" style="background-color:white;"></canvas>
                                    <p class="text-muted text-center" style="font-size: 14px;padding-bottom:10px;background-color:#fff;"><small>Source: <a href="https://www.who.int/tools/child-growth-standards/standards/length-height-for-age" target="_blank">WHO</a></small></p>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fade" v-bind:class="[opt.backdrop]"></div>
    </div>
</template>
<script>
import DokterService from '@/services/DokterService';
import { getError } from '@/utils/helpers';
import ChartJsPluginDatalabels from "chartjs-plugin-datalabels"
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import {
    Chart,
    LineController,
    Title,
    Legend,
    CategoryScale,
    LinearScale,
    Tooltip,
    PointElement,
    LineElement,
} from 'chart.js';
Chart.register(LineController,
CategoryScale, LinearScale, 
Title, Legend, Tooltip, 
PointElement,LineElement,ChartJsPluginDatalabels);

export default {
    name: 'ResultCalculator',
    components: { VueSlider },
    data() {
        return {
            tabs:"value",
            colorGirls:'#e45bbf',
            colorBoy:'#3096d7',
            opt: this.options,
            dataset: this.set_data,
            loadKalkulator: false,
            loadDosisSaji: false,
            loadSaranSaji: false,
            loadSlider: false,
            loadVolume:false,
            reload: this.reload_kalkulator,
            output: null,
            result: null,
            category: 0,
            susu: 0,
            select_susu:0,
            persen_kalori: '50',
            dosis_saji: 0,
            saran_saji: 0,
            volume:0,
            listCate: [],
            listSusu: [],
            loadSave: false,
            catatan:"",
            dataSlider: {
                0: '0%',
                5: '5%',
                10: '10%',
                15: '15%',
                20: '20%',
                25: '25%',
                30: '30%',
                35: '35%',
                40: '40%',
                45: '45%',
                50: '50%',
                55: '55%',
                60: '60%',
                65: '65%',
                70: '70%',
                75: '75%',
                80: '80%',
                85: '85%',
                90: '90%',
                95: '95%',
                100: '100%',
            },
            img_susu : "",
            wa_disabled:true,
            wa_load:false,
            id_history:""
        };
    },
    props: {
        options: Object,
        reload_kalkulator: Boolean,
        set_data: Object,
    },
    model: {
        prop: 'reload_kalkulator',
        event: 'load',
    },
    created() {
       this.getSusu();
      
    },
    mounted() {
     
    },
    computed: {
        
    },
    methods: {
        
        sendWa(){
            this.wa_load = true
            this.loadSave = true
            this.onLoadKalkulator();
        },
        async onListenWa() {
            const payload = {
                id_history: this.id_history,
            };
            await DokterService.sendWa(payload)
            .then((response) => this.onResultWa(response))
            .catch((error) => {console.log(error)});
        },
        onResultWa(response){
            if(response.data.result == "success") {
                this.opt.isShowModal = '';
                this.opt.displayModal = 'none';
                this.opt.backdrop = '';

                this.dataset.name = '';
                this.dataset.gender = '';
                this.dataset.birth = '';
                this.dataset.berat = 0;
                this.dataset.tinggi = 0;
                this.persen_kalori = '50';
                this.category = 0;
                this.susu = 0;

                 this.$store.dispatch('auth/setMenuActive', {
                        value: 'pasien',
                });

                this.$emit('reload_result', false);
                this.$router.push({name:"pasien_detail",params:{id:this.dataset.id}});
            }
            this.wa_load = false
        },
        closeModal() {
            this.opt.isShowModal = '';
            this.opt.displayModal = 'none';
            this.opt.backdrop = '';
            this.persen_kalori = '50';
            this.category = 0;
            this.susu = 0;
            this.$emit('reload_result', false);
        },
        async onLoadKalkulator() {
            const payload = {
                save: this.loadSave,
                id_pasien: this.dataset.id,
                berat_badan: this.dataset.berat,
                tinggi_badan: this.dataset.tinggi,
                persen_kalori: this.persen_kalori,
                volume: this.volume,
                catatan:this.catatan
            };

            if (this.susu != 0) {
                payload.id_susu = this.susu;
            }
            if (this.dosis_saji != 0) {
                payload.dosis_saji = this.dosis_saji;
            }
            if (this.saran_saji != 0) {
                payload.saran_saji = this.saran_saji;
            }

            await DokterService.getKalkulator(payload)
                .then((response) => this.onResultKalulator(response))
                .catch((error) => {console.log(error)});
        },
        async getSusu() {
            const payload = {
                params: {
                    category_id: this.category,
                },
            };
            await DokterService.getSusu(payload)
                .then((response) => this.onResultSusu(response))
                .catch((error) => getError(error));
        },
        onResultKalulator(response) {
            if (this.loadSave == true && this.wa_load == false) {
                this.opt.isShowModal = '';
                this.opt.displayModal = 'none';
                this.opt.backdrop = '';

                this.dataset.name = '';
                this.dataset.gender = '';
                this.dataset.birth = '';
                this.dataset.berat = 0;
                this.dataset.tinggi = 0;
                this.persen_kalori = '50';
                this.category = 0;
                this.susu = 0;

                 this.$store.dispatch('auth/setMenuActive', {
                        value: 'pasien',
                });

                this.$emit('reload_result', false);
                this.$router.push({name:"pasien_detail",params:{id:this.dataset.id}});

            }

            if(response.data.susu) {
                if(response.data.susu.image_url) {
                    this.img_susu = response.data.susu.image_url
                }
            }   

          

            this.output = response.data.output;
            this.result = response.data;
            // console.log(response.data.chart)
            this.onChartLfa(response.data.chart.lfa)
            this.onChartWfa(response.data.chart.wfa)
            this.onChartWfl(response.data.chart.wfl)
            this.loadKalkulator = false;
            this.loadSusu = false;
            this.loadDosisSaji = false;
            this.loadSaranSaji = false;
            this.loadSlider = false;
            this.loadSave = false;
            this.loadVolume = false

            if(this.wa_load == true) {
                this.id_history = response.data.id_history
                this.onListenWa();
            }
        },
        onResultSusu(response) {
            if (response.data.result == 'success') {
                this.listCate = response.data.kategori_susu;
                this.listSusu = response.data.susu;
            }
        },
        onChangeCategory() {
            this.getSusu();
        },
        onChangeSusu() {
            this.loadSusu = true;
            this.onLoadKalkulator();
        },
        onCalculateDosisSaji() {
            this.loadDosisSaji = true;
            this.onLoadKalkulator();
        },
        onCalcaulateVolume() {
            this.loadVolume = true
           this.onLoadKalkulator();
        },
        onCalculateSaranSaji() {
            this.loadSaranSaji = true;
            this.onLoadKalkulator();
        },
        onChangSlider() {
            this.loadSlider = true;
            this.onLoadKalkulator();
            console.log(this.persen_kalori);
        },
        onSave() {
            this.loadSave = true;
            this.onLoadKalkulator();
        },
        onTabs(value) {
            this.tabs = value
        },
        onChartWfa(result) {
            var context = document.getElementById('chart_priview_wfa');
            if (window.chart_wfa != null) {
                window.chart_wfa.destroy();
            }

            const labels = result.x_axis;
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: '-3',
                        data: result.sd3neg,
                        fill: false,
                        pointRadius: 0,
                        borderColor: '#000',
                        datalabels: {
                            color: '#000',
                        },
                    },
                    {
                        label: '-2',
                        data: result.sd2neg,
                        fill: false,
                        borderColor: 'red',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: 'red',
                        },
                    },
                    {
                        label: '-1',
                        data: result.sd1neg,
                        fill: false,
                        borderColor: '#000',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: '#000',
                        },
                    },
                    {
                        label: '0',
                        data: result.sd0,
                        fill: false,
                        borderColor: 'green',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: 'green',
                        },
                    },
                    {
                        label: '1',
                        data: result.sd1,
                        fill: false,
                        borderColor: '#000',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: '#000',
                        },
                    },
                    {
                        label: '2',
                        data: result.sd2,
                        fill: false,
                        borderColor: 'red',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: 'red',
                        },
                    },
                    {
                        label: '3',
                        data: result.sd3,
                        fill: false,
                        borderColor: '#000',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: '#000',
                        },
                    },
                    
                    {
                        label:"Weight",
                        data: result.pasien.data,
                        fill: false,
                        borderColor: '#4D3180',
                        backgroundColor:'#4D3180',
                        pointRadius: 5,
                        datalabels: {
                            color: '#4D3180',
                        },
                    },
                ],
            };

             window.chart_wfa = new Chart(context, {
                type: 'line',
                data: data,
                options: {
                    layout: {
                        padding: {
                            right: 50,
                        },
                    },
                    
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Age (completed months and years)',
                            },
                            gridLines: {
                                display: true,
                                drawOnChartArea: false,
                            },
                            max:result.x_axis[result.x_axis.length - 1],
                            min:result.x_axis[0],
                            ticks:{
                                autoSkip: true,
                                maxTicksLimit:result.x_axis.length,
                                callback: function(value, index) {
                                    var mod     = result.x_axis[index] % 12;
                                    var val_mod = result.x_axis[index] / 12;
                                    if(mod == 0){
                                        if(index == 0)
                                        {
                                            return 'Birth';
                                        } else {
                                            return val_mod+' years';
                                        }
                                    } else if (result.x_axis[index] > 12) {
                                        if( result.x_axis[index] % 12 == 1)
                                        {
                                            return '';
                                        }else{
                                            return result.x_axis[index] % 12;
                                        }
                                    } 
                                    else {
                                        return result.x_axis[index];
                                    }
                                }
                            },
                            
                        },
                        y: {
                            title: {
                                display: true,
                                text: 'Weight (kg)',
                            },
                            gridLines: {
                                display: true,
                            },
                            max:result.sd3[result.sd3.length - 1] + 5,
                            min:result.sd3neg[0],
                            ticks: {
                                suggestedMin: 0,
                                autoSkip: true,
                                stepSize:2,
                                maxTicksLimit:14,
                                callback: function(value, index) {
                                if(result.sd3neg[0] == result.sd3neg[index])
                                {
                                    return '';
                                } else {
                                    return Math.floor(value);
                                }
                                }
                            }
                            
                        },
                    },
                    plugins: {
                        beforeDraw: (chart) => {
                            const ctx = chart.canvas.getContext('2d');
                            ctx.save();
                            ctx.globalCompositeOperation = 'destination-over';
                            ctx.fillStyle = 'lightGreen';
                            ctx.fillRect(0, 0, chart.width, chart.height);
                            ctx.restore();
                        },
                        tooltip: {
                            displayColors: false,
                            callbacks: {
                                title:function(){
                                    return '';
                                },
                                label: function(context) {
                                    return 'Weight : '+context.parsed.y+' kg';
                                },
                                beforeLabel: function(context) {
                                    return 'Age : '+context.parsed.x+' month';
                                },
                                labelColor: function () {
                                    return {
                                        borderColor: 'rgba(255, 0, 0, 0)',
                                        backgroundColor: 'rgba(255, 0, 0, 0)',
                                        borderWidth: 0,
                                        borderDash: [0, 0],
                                        borderRadius: 0,
                                    }
                                }

                            }
                        },
                        title: {
                            display: true,
                            text: result.title,
                        },
                        legend: {
                            display: false,
                        },
                    
                        datalabels: {
                            anchor: 'center',
                            align: 'right',
                            offset: 10,
                            font: {
                                weight: 'bold',
                            },
                            display: function(context) {
                                return (context.dataIndex === result.x_axis.length - 1);
                            },
                            formatter: function(value, context) {
                                return context.dataset.label;
                            },
                        },
                        // zoom: {
                        //     zoom: {
                        //     wheel: {
                        //         enabled: true,
                        //     },
                        //     pinch: {
                        //         enabled: true
                        //     },
                            
                        //     limits: {
                        //         y: {min: 0, max: 500},
                        //         x:{min:result.x_axis[0],max:result.x_axis[result.x_axis.length - 1]}
                        //     },
                        //     overScaleMode:'x',
                        //     mode: 'xy',
                        //     }
                        // }
                    }
                },
             });
             window.chart_wfa.update();
        },
        onChartWfl(result){
            var context = document.getElementById('chart_priview_wfl');
            if (window.chart_wfl != null) {
                window.chart_wfl.destroy();
            }

            const labels = result.x_axis;
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: '-3',
                        data: result.sd3neg,
                        type:'line',
                        fill: false,
                        pointRadius: 0,
                        tension: 0,
                        borderColor: '#000',
                        datalabels: {
                            color: '#000',
                        },
                    },
                    {
                        label: '-2',
                        data: result.sd2neg,
                        fill: false,
                        borderColor: 'red',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: 'red',
                        },
                    },
                    {
                        label: '-1',
                        data: result.sd1neg,
                        fill: false,
                        borderColor: '#000',
                        tension: 0.4,
                        pointRadius: 0,
                        steppedLine:false,
                        datalabels: {
                            color: '#000',
                        },
                    },
                    {
                        label: '0',
                        data: result.sd0,
                        fill: false,
                        borderColor: 'green',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: 'green',
                        },
                    },
                    {
                        label: '1',
                        data: result.sd1,
                        fill: false,
                        borderColor: '#000',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: '#000',
                        },
                    },
                    {
                        label: '2',
                        data: result.sd2,
                        fill: false,
                        borderColor: 'red',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: 'red',
                        },
                    },
                    {
                        label: '3',
                        data: result.sd3,
                        fill: false,
                        type:'line',
                        borderColor: '#000',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: '#000',
                        },
                    },
                    {
                        label: result.pasien.name,
                        data:[{x:result.pasien.data[0].x,y:result.pasien.data[0].y}],
                        fill: false,
                        borderColor: '#4D3180',
                        backgroundColor:'#4D3180',
                        pointRadius: 5,
                        datalabels: {
                            color: '#4D3180',
                        },
                    },
                ],
            };

            window.chart_wfl = new Chart(context, {
                type: 'line',
                data: data,
                options: {
                    tension:0.4,
                    layout: {
                        padding: {
                            right: 50,
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Length/height (cm)',
                            },
                            stacked:true,
                            max:result.x_axis[result.x_axis.length - 1],
                            min:result.x_axis[0],
                            ticks:{
                                autoSkip: true,
                                maxTicksLimit:14,
                                stepSize:5,
                                callback: function(value) {
                                    if (result.x_axis[value] % 5 == 0) {
                                        return result.x_axis[value];
                                    } 
                                }
                            }
                        },
                        y: {
                            id:'yAxesMain',
                            title: {
                                display: true,
                                text: 'Weight (kg)',
                            },
                            // max:result.pasien.data[0].y * 2,
                            // min:result.sd,
                            ticks: {
                                autoSkip: false,
                                stepSize:2,
                            }
                            
                        },
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: result.title,
                        },
                        tooltip: {
                            displayColors: false,
                            callbacks: {
                                title:function(){
                                    return '';
                                },
                                label: function() {
                                    return 'Weight : '+result.pasien.data[0].y+' kg';
                                },
                                beforeLabel: function() {
                                    return 'Length/height : '+result.pasien.data[0].x+' cm';
                                },
                                

                            }
                        },
                        legend: {
                            display: false,
                        },
                        datalabels: {
                            anchor: 'center',
                            align: 'right',
                            offset: 10,
                            font: {
                                weight: 'bold',
                            },
                            display: function(context) {
                                return (context.dataIndex === result.x_axis.length - 1);
                            },
                            formatter: function(value, context) {
                                return context.dataset.label;
                            },
                        },
                        // zoom: {
                        //     zoom: {
                        //     wheel: {
                        //         enabled: true,
                        //     },
                        //     pinch: {
                        //         enabled: true
                        //     },
                            
                        //     limits: {
                        //         y: {min: 0, max: 500},
                        //         x:{min:result.x_axis[0],max:result.x_axis[result.x_axis.length - 1]}
                        //     },
                        //     overScaleMode:'x',
                        //     mode: 'xy',
                        //     }
                        // }
                    },
                
                },
            })
            window.chart_wfl.update();
        },
        onChartLfa(result){
            var context = document.getElementById('chart_priview_lfa');
            if (window.chart_lfa != null) {
                window.chart_lfa.destroy();
            }

            const labels = result.x_axis;
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: '-3',
                        data: result.sd3neg,
                        fill: false,
                        pointRadius: 0,
                        borderColor: '#000',
                        datalabels: {
                            color: '#000',
                        },
                    },
                    {
                        label: '-2',
                        data: result.sd2neg,
                        fill: false,
                        borderColor: 'red',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: 'red',
                        },
                    },
                    {
                        label: '-1',
                        data: result.sd1neg,
                        fill: false,
                        borderColor: '#000',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: '#000',
                        },
                    },
                    {
                        label: '0',
                        data: result.sd0,
                        fill: false,
                        borderColor: 'green',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: 'green',
                        },
                    },
                    {
                        label: '1',
                        data: result.sd1,
                        fill: false,
                        borderColor: '#000',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: '#000',
                        },
                    },
                    {
                        label: '2',
                        data: result.sd2,
                        fill: false,
                        borderColor: 'red',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: 'red',
                        },
                    },
                    {
                        label: '3',
                        data: result.sd3,
                        fill: false,
                        borderColor: '#000',
                        tension: 0.4,
                        pointRadius: 0,
                        datalabels: {
                            color: '#000',
                        },
                    },
                    {
                        label: result.pasien.name,
                        data: [{x:result.pasien.data[0].x,y:result.pasien.data[0].y}],
                        fill: false,
                        borderColor: '#4D3180',
                        backgroundColor:'#4D3180',
                        pointRadius: 5,
                        datalabels: {
                            color: '#4D3180',
                        },
                    },
                ],
            };

            window.chart_lfa = new Chart(context, {
                type: 'line',
                data: data,
                options: {
                     layout: {
                        padding: {
                            right: 50,
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Age (completed months and years)',
                            },
                            gridLines: {
                                display: true,
                            },
                            max:result.x_axis[result.x_axis.length - 1],
                            min:result.x_axis[0],
                            ticks:{
                                autoSkip: true,
                                maxTicksLimit:result.x_axis.length,
                                callback: function(value, index) {
                                    var mod     = result.x_axis[index] % 12;
                                    var val_mod = result.x_axis[index] / 12;
                                    if(mod == 0){
                                        if(index == 0)
                                        {
                                            return 'Birth';
                                        } else {
                                            return val_mod+' years';
                                        }
                                    } else if (result.x_axis[index] > 12) {
                                        if( result.x_axis[index] % 12 == 1)
                                        {
                                            return '1';
                                        }else{
                                            return result.x_axis[index] % 12;
                                        }
                                    } 
                                    else {
                                        return result.x_axis[index];
                                    }
                                }
                            },
                            
                        },
                        y: {
                            title: {
                                display: true,
                                text: 'Length (cm)',
                            },
                            max:result.sd3[result.sd3.length - 1] + 5,
                            min:result.sd3neg[0],
                            ticks: {
                                suggestedMin: 0,
                                autoSkip: true,
                                stepSize:5,
                                maxTicksLimit:11,
                                callback: function(value, index) {
                                if(result.sd3neg[0] == result.sd3neg[index])
                                {
                                    return '';
                                } else {
                                    return Math.floor(value);
                                }
                                }
                            }
                            
                        },
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: result.title,
                        },
                        tooltip: {
                            displayColors: false,
                            callbacks: {
                                title:function(){
                                    return '';
                                },
                                label: function(context) {
                                    return 'Length : '+context.parsed.y+' cm';
                                },
                                beforeLabel: function() {
                                    return 'Age  : '+result.pasien.data[0].x+' month';
                                },
                                

                            }
                        },
                        legend: {
                            display: false,
                        },
                        datalabels: {
                            anchor: 'center',
                            align: 'right',
                            offset: 10,
                            font: {
                                weight: 'bold',
                            },
                            display: function(context) {
                                return (context.dataIndex === result.x_axis.length - 1);
                            },
                            formatter: function(value, context) {
                                value
                                return context.dataset.label;
                            },
                        },
                        // zoom: {
                        //     zoom: {
                        //     wheel: {
                        //         enabled: true,
                        //     },
                        //     pinch: {
                        //         enabled: true
                        //     },
                            
                        //     limits: {
                        //         y: {min: 0, max: 500},
                        //         x:{min:result.x_axis[0],max:result.x_axis[result.x_axis.length - 1]}
                        //     },
                        //     overScaleMode:'x',
                        //     mode: 'xy',
                        //     }
                        // }
                    }
                },
            });
            window.chart_lfa.update();
        }
    },
    watch: {
        reload_kalkulator: {
            handler(val) {
                if (val == true) {
                    this.loadKalkulator = true;
                    this.onLoadKalkulator();
                }
            },
            deep: true,
        },
        loadKalkulator:function(e, newValue) {
            if(newValue) {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    setTimeout(() => {
                        const item_slider = document.getElementsByClassName("vue-slider-mark-label");
                        for(var i =0; i<item_slider.length; i++) {
                                if(i % 5 ) {
                                    item_slider[i].style.display = "none";
                                }
                        }
                    }, 1000);
                } 
                
            }
        }
    },
};
</script>
<style>
.tab-content .active {
    background-color: transparent !important;
}
.nav-pills .nav-link {
    border-radius: 8px !important;
}
.active {
    background-color: #0d6efd !important;
    color: #fff;
}
.vue-slider {
    padding: 8px 23px !important;
    width: auto !important;
    height: 14px !important;
}
.vue-slider-process {
    background-color: #4d3180;
}
.vue-slider-mark-label{
    color:#2b3674;
}
</style>
