<template>
    <div>
        <div
            class="modal fade"
            v-bind:class="[opt.isShowModal]"
            v-bind:style="{ display: opt.displayModal }"
            id="searchPatient"
            tabindex="-1"
            aria-labelledby="searchPatientLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
        >
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content pb-3">
                    <div class="d-flex">
                        <button
                            style="margin:15px 22px;"
                            type="button"
                            v-on:click="closeModal()"
                            class=" ms-auto btn-close"
                        ></button>
                    </div>
                    <div class="p-4 modal-top">
                        <!-- <button
                            type="button"
                            v-on:click="closeModal()"
                            class="
                                ms-auto
                                btn-close
                                rounded-circle
                                border border-3
                                mb-3
                                border-dark
                            "
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button> -->
                        <form @submit.prevent="onLoadPasien">
                            <div class="search-input d-flex">
                                <input
                                    v-on:keyenter="onFilter()"
                                    type="text"
                                    v-model="patientSearch"
                                    name="patient"
                                    id="patient-search"
                                    class="form-control"
                                    placeholder="Cari Pasien"
                                />
                                <i class="fas fa-search"></i>
                                <div class="ms-3">
                                    <button
                                        class="
                                            btn btn-add-main
                                            px-3
                                            mt-1
                                            text-nowrap
                                        "
                                        type="button"
                                        v-on:click="openFormPasien()"
                                    >
                                        Tambah Pasien
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="table-responsive">
                        <table class="table align-middle avenir-light">
                            <thead>
                                <tr>
                                    <th scope="col">No</th>
                                    <th scope="col">Nama Lengkap</th>
                                    <th scope="col">Tanggal Lahir</th>
                                    <th scope="col">Umur</th>
                                    <th scope="col">No HP</th>
                                    <th scope="col">Terakhir Periksa</th>
                                    <th scope="col">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="loadPasien">
                                    <td colspan="7" class="text-center">
                                        <h1>
                                            <i
                                                class="fa fa-spinner fa-spin"
                                            ></i>
                                        </h1>
                                    </td>
                                </tr>
                                <template
                                    v-for="(item, index) in listPasien"
                                    v-else
                                >
                                    <tr :key="item.id">
                                        <td>{{ index + index_data }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.birth_date_format }}</td>
                                        <td>{{ item.age }}</td>
                                        <td>{{ item.phone }}</td>
                                        <td>{{ item.last_check_format }}</td>
                                        <td>
                                            <button
                                                v-on:click="
                                                    onSelectPasien(item)
                                                "
                                                class="
                                                    btn btn-add-main
                                                    px-3
                                                    mt-1
                                                    text-nowrap
                                                    py-2
                                                "
                                            >
                                                Pilih Pasien
                                            </button>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>

                    <div class="table-info" v-if="paging_show">
                        <h6 class="mb-0 text-muted">
                            <small>Rows per page: </small>
                        </h6>
                        <div class="dropdown-container">
                            <select
                                name="rows"
                                id="pageRow"
                                class="form-select page-select"
                                v-on:change="onChangePerpage()"
                                v-model="perpage"
                            >
                                <option value="7">7</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                            </select>
                            <!-- <i class="fas fa-chevron-down"></i> -->
                        </div>
                        <div class="mx-3 paging-of">
                            <small>{{ page_info }}</small>
                        </div>
                        <button
                            class="btn"
                            :disabled="disabled_prev"
                            v-on:click="prevPage()"
                        >
                            <i class="fas fa-chevron-left"></i>
                        </button>
                        <button
                            class="btn"
                            :disabled="disabled_next"
                            v-on:click="nextPage()"
                        >
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="fade" v-bind:class="[opt.backdrop]"></div>
    </div>
</template>
<script>
import DokterService from '@/services/DokterService';
import { getError } from '@/utils/helpers';

export default {
    name: 'Searchpasien',
    data() {
        return {
            opt: this.options,
            loadPasien: false,
            listPasien: [],
            f_pasien: this.form_pasien,
            dataset: this.set_data,
            patientSearch: '',
            page: 1,
            perpage: 7,
            last_page: 1,
            page_info: '',
            index_data: 1,
            paging_show:false,
            disabled_next: false,
            disabled_prev: false,
        };
    },
    created() {
        this.onLoadPasien();
    },
    props: {
        pasienlist: Array,
        options: Object,
        form_pasien: Object,
        reload_pasien: Boolean,
        set_data: Object,
    },
    model: {
        prop: 'reload_pasien',
        event: 'load',
    },
    methods: {
        closeModal() {
            this.opt.isShowModal = '';
            this.opt.displayModal = 'none';
            this.opt.backdrop = '';
            this.$emit('reload_emit', false);
        },
        async onLoadPasien() {
            this.loadPasien = true;
            const payload = {
                params: {
                    search: this.patientSearch,
                    perpage: this.perpage,
                    page: this.page,
                },
            };
            await DokterService.getPasien(payload)
                .then((response) => this.onResultPasien(response))
                .catch((error) => getError(error));
        },
        onResultPasien(response) {
            this.listPasien = response.data.data;
            this.loadPasien = false;
            this.last_page = response.data.last_page;
            if(this.listPasien.length > 0) {
                this.paging_show = true
            } else {
                this.paging_show = false
            }
            this.page_info =
                response.data.current_page +
                '-' +
                response.data.last_page +
                ' of ' +
                response.data.to;
            this.index_data = response.data.from;

            if (this.page == 1) {
                this.disabled_prev = true;
            } else {
                this.disabled_prev = false;
            }

            if (this.page < this.last_page) {
                this.disabled_next = false;
            } else {
                this.disabled_next = true;
            }
        },
        openFormPasien() {
            this.closeModal();
            if (this.f_pasien.isShowModal == 'show') {
                this.f_pasien.isShowModal = '';
                this.f_pasien.displayModal = 'none';
                this.f_pasien.backdrop = '';
                this.f_pasien.returnResult = false;
            } else {
                this.f_pasien.returnResult = true;
                this.f_pasien.isShowModal = 'show';
                this.f_pasien.displayModal = 'block';
                this.f_pasien.backdrop = 'modal-backdrop show';
            }
        },
        onSelectPasien(item) {
            this.dataset.id = item.id;
            this.dataset.birth = item.birth_date;
            this.dataset.gender = item.gender_format;
            this.dataset.gender_code = item.gender;
            this.dataset.name = item.name;
            this.dataset.parent = item.parent_name;
            this.dataset.phone = item.phone;
            this.dataset.age = item.age;
            this.dataset.berat = "";
            this.dataset.tinggi = "";

            this.opt.isShowModal = '';
            this.opt.displayModal = 'none';
            this.opt.backdrop = '';
            this.$emit('reload_emit', false);
        },
        onFilter() {
            console.log('test');
            this.page = 1;
            this.onLoadPasien();
        },
        nextPage() {
            if (this.page < this.last_page) {
                this.page++;
                this.onLoadPasien();
            }

            if (this.page < this.last_page) {
                this.disabled_next = false;
            } else {
                this.disabled_next = true;
            }
        },
        prevPage() {
            if (this.page > 1) {
                this.page--;
                this.onLoadPasien();
            }

            if (this.page == 1) {
                this.disabled_prev = true;
            } else {
                this.disabled_prev = false;
            }
        },
        onChangePerpage() {
            this.page = 1;
            this.onLoadPasien();
        },
    },
    watch: {
        reload_pasien: {
            handler(val) {
                if (val == true) {
                    this.patientSearch = '';
                    this.page = 1;
                    this.onLoadPasien();
                }
            },
            deep: true,
        },
    },
};
</script>
