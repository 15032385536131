<template>
    <div class="container-fluid">
        <div
            class="page-content ml-sm-0"
            id="page-content"
        >
            <!-- <div class="header-greeting mb-3 pe-md-5">
                <p class="p-0 m-0">Recopedia</p>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <h1 class="fw-bolder">RX Calculator</h1>
                    </div>
                </div>
            </div> -->
            <section id="main-calc" class="mb-5">
                <div class="row">
                    <div class="col-sm-12 col-md-9">
                        <form action="#" id="rx-calc">
                            <div class="calc-wrapper p-4">
                                <h3 class="mb-3">Kalkulator</h3>
                                <div class="form-group my-3">
                                    <div class="search-input d-flex">
                                        <div class="row">
                                            <div class="col-md-10 mb-2">
                                                <input
                                                    type="text"
                                                    name="patient"
                                                    v-on:focus="
                                                        openListPasien()
                                                    "
                                                    id="patient-search"
                                                    class="form-control"
                                                    placeholder="Cari Pasien"
                                                    :value="responseData.name"
                                                />
                                                <i class="fas fa-search"></i>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="ms-3">
                                                    <button
                                                        class="
                                                            btn btn-add-main
                                                            px-5
                                                            text-nowrap
                                                        "
                                                        type="button"
                                                        v-on:click="
                                                            openFormPasien()
                                                        "
                                                    >
                                                        Tambah Pasien
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="gender" class="mb-2"
                                        >Jenis Kelamin</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="gender"
                                        :value="responseData.gender"
                                        readonly
                                        id="gender"
                                        placeholder="Masukkan Jenis Kelamin Pasien"
                                    />
                                </div>
                                <div class="form-group mb-3">
                                    <label for="birthdate" class="mb-2"
                                        >Tanggal Lahir (Umur)</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="birthdate"
                                        :value="responseData.birth"
                                        id="birthdate"
                                        readonly
                                        placeholder="Masukkan Umur Pasien (Tanggal Lahir DD-MM-YYYY)"
                                    />
                                </div>
                                <div class="form-group mb-3">
                                    <label for="weight" class="mb-2"
                                        >Berat Badan (Kg)</label
                                    >
                                    <input
                                        type="number"
                                        class="form-control"
                                        name="weight"
                                        v-model="responseData.berat"
                                        id="weight"
                                        placeholder="Contoh: 10.5"
                                    />
                                </div>
                                <div class="form-group mb-3">
                                    <label for="height" class="mb-2"
                                        >Tinggi Badan (cm)</label
                                    >
                                    <input
                                        type="number"
                                        class="form-control"
                                        name="height"
                                        v-model="responseData.tinggi"
                                        id="height"
                                        placeholder="Contoh: 70.1"
                                    />
                                </div>
                            </div>
                            <div class="d-grid mt-3">
                                <button
                                    v-on:click="openResult()"
                                    type="button"
                                    class="btn btn-main py-2 avenir-light"
                                >
                                    MULAI HITUNG
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
        <search-pasien
            v-model="reload"
            @reload_emit="onReloadPasien"
            :options="optionsListPasien"
            :form_pasien="optionAddPasien"
            :set_data="responseData"
        ></search-pasien>
        <result-calculator
            :options="optionsResult"
            v-model="reload_kalkulator"
            :set_data="responseData"
            @reload_result="onResultKalkulator"
        ></result-calculator>
        <add-pasien
            :options="optionAddPasien"
            @reload_emit="onReloadPasien"
            :set_data="responseData"
        ></add-pasien>
    </div>
</template>
<script>
import SearchPasien from '@/components/SearchPasien';
import ResultCalculator from '@/components/ResultCalculator';
import AddPasien from '@/components/AddPasien';

export default {
    name: 'Kalkulator',
    data() {
        return {
            pasienlist: [],
            reload: false,
            reload_kalkulator: false,
            weight: 0,
            height: 0,
            optionsListPasien: {
                isShowModal: '',
                backdrop: '',
                displayModal: 'none',
            },
            optionsResult: {
                isShowModal: '',
                backdrop: '',
                displayModal: 'none',
            },
            optionAddPasien: {
                isShowModal: '',
                backdrop: '',
                displayModal: 'none',
                returnResult: true,
            },
            responseData: {
                id: '',
                gender: '',
                gender_code: '',
                name: '',
                birth: '',
                berat: "",
                tinggi: "",
                phone: '',
                age: '',
                parent: '',
            },
        };
    },

    components: { SearchPasien, ResultCalculator, AddPasien },
    computed: {
        computeMarginLeft: function () {
            const width = window.innerWidth;
            if (width >= 768) {
                return '280px';
            }

            if (width < 768) {
                return '80px';
            }

            return '0px';
        },
    },
    created(){
         this.$store.dispatch('auth/setMenuActive', { value: 'kalkulator' });
         document.title = 'Kalkulator'
    },
    methods: {
        openListPasien() {
            this.reload = true;
            if (this.optionsListPasien.isShowModal == 'show') {
                this.optionsListPasien.isShowModal = '';
                this.optionsListPasien.displayModal = 'none';
                this.optionsListPasien.backdrop = '';
            } else {
                this.optionsListPasien.isShowModal = 'show';
                this.optionsListPasien.displayModal = 'block';
                this.optionsListPasien.backdrop = 'modal-backdrop show';
            }
        },
        onKey() {
            this.responseData.berat = this.weight;
            this.responseData.tinggi = this.height;
        },
        openResult() {
            this.reload_kalkulator = true;
            if (this.optionsResult.isShowModal == 'show') {
                this.optionsResult.isShowModal = '';
                this.optionsResult.displayModal = 'none';
                this.optionsResult.backdrop = '';
            } else {
                this.optionsResult.isShowModal = 'show';
                this.optionsResult.displayModal = 'block';
                this.optionsResult.backdrop = 'modal-backdrop show';
            }
        },
        openFormPasien() {
            if (this.optionAddPasien.isShowModal == 'show') {
                this.optionAddPasien.isShowModal = '';
                this.optionAddPasien.displayModal = 'none';
                this.optionAddPasien.backdrop = '';
                this.optionAddPasien.returnResult = false;
            } else {
                this.optionAddPasien.returnResult = true;
                this.optionAddPasien.isShowModal = 'show';
                this.optionAddPasien.displayModal = 'block';
                this.optionAddPasien.backdrop = 'modal-backdrop show';
            }
        },
        onResultKalkulator(result) {
            this.reload_kalkulator = result;
        },
        onReloadPasien(result) {
            this.reload = result;
        },
    },
};
</script>
